<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 12.023 9.688"
  >
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      d="m1.409 5.376 2.719 3.312L7.89 4.467l2.723-3.055"
    />
  </svg>
</template>
